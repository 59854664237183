
import {defineComponent, ref, computed} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {injectOrThrow} from "@/helpers/inject";
import {Rules, TypeResources, TypeLinks} from "@/models";
import {useForm, useField} from 'vee-validate';
import {ucFirst} from '@/helpers/firstRegister';

export default defineComponent({
	emits: ["dialog-closed", "item-added"],
	props: {
		open: {
			type: Boolean,
			default: false
		}
	},
	setup(_, {emit}) {
		const { t } = useI18n();
		const store = useStore();

		const {handleSubmit, handleReset} = useForm();

		const typeResources = computed(() => store.state.dictionaries.typeResources as TypeResources.TypeResource[]);

		const { value: selectedTypeResource1, errorMessage: typeResourceError1 } = useField<TypeResources.TypeResource>('selectedTypeResource1', 'required');
		function selectTypeResource1(selected: TypeResources.TypeResource) {
			selectedTypeResource1.value = selected ?? {};
		}

		const { value: selectedTypeResource2, errorMessage: typeResourceError2 } = useField<TypeResources.TypeResource>('selectedTypeResource2', 'required');
		function selectTypeResource2(selected: TypeResources.TypeResource) {
			selectedTypeResource2.value = selected ?? {};
		}

		const { value: master_resource_position, errorMessage: masterResourcePositionError } = useField<number>('master_resource_position', 'required|decimal');

		const if_friendly = ref(false);

		const typeLinks = computed(() => store.state.dictionaries.typeLinks as TypeLinks.TypeLink[]);

		const { value: selectedTypeLink, errorMessage: typeLinkError } = useField<TypeLinks.TypeLink>('selectedTypeLink', 'required');
		function selectTypeLink(selected: TypeLinks.TypeLink) {
			selectedTypeLink.value = selected ?? {};
		}

		const { value: selectedTypeLinkClosed, errorMessage: typeLinkClosedError } = useField<number[]>('selectedTypeLinkClosed', '');
		function selectTypeLinkClosed(selected: number[]) {
			selectedTypeLinkClosed.value = selected ?? [];
		}

		const isLoading = ref(false);
		const service = injectOrThrow<Rules.RuleService>('Rules.RuleService');

		const add = handleSubmit(async () => {
			isLoading.value = true;

			try {
				await service.addPair({
					id_type_res1: selectedTypeResource1.value.id,
					id_type_res2: selectedTypeResource2.value.id,
					id_enterprise: store.state.globals.enterpriseId,
					master_resource_position: master_resource_position.value,
					if_friendly: if_friendly.value,
					link: selectedTypeLink.value.id,
					link_close_list: selectedTypeLinkClosed.value
				});

				store.commit('notification/successMessage', {title: t("form.notify.success.added", { name: ucFirst(t("dictionary.rule")) })});
				emit("item-added");
				closeModal();
			} catch (e) {
				return;
			} finally {
				isLoading.value = false;
			}
		});

		const closeModal = () => {
			emit('dialog-closed');
			handleReset();
		}

		return {
			t,
			typeResources,
			selectedTypeResource1, typeResourceError1, selectTypeResource1,
			selectedTypeResource2, typeResourceError2, selectTypeResource2,
			master_resource_position, masterResourcePositionError,
			if_friendly,
			typeLinks,
			selectedTypeLink, typeLinkError, selectTypeLink,
			selectedTypeLinkClosed, typeLinkClosedError, selectTypeLinkClosed,
			isLoading, add, closeModal
		}
	}
})
